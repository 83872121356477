import React, { useState, useEffect, Fragment, useRef } from "react";
import { 
  Wrapper,
  Container, 
  HorizontalLine, 
  VerticalLine, 
  GridItem, 
  TeacherImage,
  TeacherTitle,
  TeacherLink,
  TeacherMobileDetails,
  SectionTitle, 
  FillerDiv,
  MarginLines,
  GridWrapper,
  YearDetails, 
  YearsContainer,
  Year} from "./style";
import { Star } from "../Star";
import sanityClient from "../../client.js";
import { FloatingMenu } from "../Menu/FloatingMenu";
import { BackButton } from "../Menu/BackButton";
import { LoadingScreen } from "../LoadingScreen";
import { GREEN } from "../../constants/colors";
import { Footer } from "../Footer";

export const TeachersPage = () => {
  const [allYears, setAllYears] = useState(null);
  const [displaying, setDisplaying] = useState('');
  const [visibleYear, setVisibleYear] = useState(allYears?.[0]?.title);
  const [key, setKey] = useState(0);
  const [maxCols, setMaxCols] = useState(0);
  const ref = useRef();


  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "teachersByYear"] | order( title desc){
          title,
          details,
          teachers[]{
            teacher->{
              name,
              handle,
              nationality,
              image{
                alt,
                caption,
                asset->{
                  url
                }
              }
            }
          },
        }`
      )
      .then((data) =>{ 
        setVisibleYear(data[0]?.title);
        setAllYears(data);

        let max = 0;
        data.forEach((row) => {
          max = row.teachers.length > max ? row.teachers.length : max;
        })
        setMaxCols(max);
      })
      .catch(console.error);
  }, []);

  const changeYear = function(title, i) {
    setVisibleYear(title);
    setDisplaying('');
    setKey(i);
    ref.current.scrollTo({left:0, behavior: 'smooth'});
  };

  return (
    <Fragment>
    {!allYears ? (
      <LoadingScreen/>
    ) : (
    <Wrapper>
      <YearsContainer rows={allYears?.length + 3}>
        {allYears?.map((year,index) => {
          return (
            <Year 
              key={"yeartitle-"+index}
              isFirstRow={index===0}
              isSelected={key===index}
              onClick={() => changeYear(year.title, index)}
              >
              <div>
                <p>{year.title}</p> 
              </div>
            </Year>
          );
        })}
          <FillerDiv isFixed={true}></FillerDiv>
          <FillerDiv isFixed={true}></FillerDiv>
          <FillerDiv isFixed={true}></FillerDiv>
      </YearsContainer>
    <GridWrapper>
    <MarginLines rows={allYears?.length} selectedRow={key}>
      {allYears?.map((year,index) => {
        return (
          <HorizontalLine  row={index+1 } key={"horizontal-line-"+index} cols={maxCols}/>
        );
      })}
      <HorizontalLine  row={allYears?.length + 1} cols={maxCols}/>
        <HorizontalLine  row={allYears?.length + 2} cols={maxCols}/>
        <HorizontalLine  row={allYears?.length + 3} cols={maxCols}/>
        <HorizontalLine  row={allYears?.length + 4} cols={maxCols}/>
    </MarginLines>
    <Container ref={ref} rows={allYears?.length} selectedRow={key} cols={maxCols}>
      <Star size={50} top={20} left={20} />
      <Star size={45} top={75 } left={60} />
      <Star size={55} top={35} left={120} />
      <Star size={40} top={75} left={160} />
      <Star size={50} top={15} left={200} />
      <Star size={50} top={80} left={240} />
      <VerticalLine col={1}  span={allYears?.length + 4}/>

      {allYears?.map((year,index) => 
        year.teachers?.map((t, j) => 
        <React.Fragment key={'teacher-'+index+'-'+j} >
          <GridItem 
              row={index+1} col={j+3} 
              key={`item-${index}-${j}`} 
              onMouseEnter={() => setDisplaying(`item-${index}-${j}`)}
              onTouchStart={() => setDisplaying(`item-${index}-${j}`)}
              show={visibleYear === year.title}>
            <img src={t?.teacher?.image?.asset?.url}
                alt={t?.teacher?.name}/>
            
          </GridItem>
          <TeacherImage 
            row={(index < 4 )? index+2 : (index < 6 ? 1 : index-5)} 
            rowSpan={(index < 4 )? 6 : (index < 6 ? index : 6)} 
            col={j < 4 ? j+3 : j+2} 
            key={`image-${index}-${j}`}
            show={displaying === `item-${index}-${j}`}>
              <img src={t?.teacher?.image?.asset?.url}
                alt={t?.teacher?.name}/>
          </TeacherImage> 
          <TeacherMobileDetails 
            row={index+2} 
            col={j+3} 
            key={`details-mobile-${index}-${j}`}
            show={displaying === `item-${index}-${j}`}
            >
              <a
                href={`https://www.instagram.com/${t?.teacher?.handle}`}
              >
                {t?.teacher?.name}
              </a>
              <p>{t?.teacher?.nationality}</p>
            </TeacherMobileDetails>
          <TeacherTitle
            row={(index < 4) ? index+2 :  (index < 6 ? 1 : index-5)} col={j < 4 ? (j === 3 ? j-1 : j+5) : j-2} right={j > 2}
            show={displaying === `item-${index}-${j}`}
            key={`title-${index}-${j}`}
          >
              <h3>{t.teacher.name}</h3>
              <h4>{t?.teacher?.nationality}</h4>
          </TeacherTitle>
          <TeacherLink
            row={(index < 4) ? index+3 :  (index < 6 ? 2 : index-4)} col={j < 4 ? (j === 3 ? j-1 : j+5) : j-2} right={j > 2}
            show={displaying === `item-${index}-${j}`}
            key={`link-${index}-${j}`}
          >
              <a
                href={`https://www.instagram.com/${t?.teacher?.handle}`}
              >
                @{t?.teacher?.handle}
              </a>
          </TeacherLink>
          <VerticalLine col={j+3} key={'vertical-line-'+j} span={allYears?.length + 4}/>
        </React.Fragment>
        )
      )}
         {allYears?.map((year,index) => {
          return (
              <HorizontalLine  row={index+1 } key={"horizontal-line-"+index} cols={maxCols}/>
          );
        })}
        {allYears?.map((year,index) => {
          return (
            <YearDetails row={index+1} selected={visibleYear === year.title} key={'year-details-'+index}>
              {year.details?.split('-').map((detail, i) => 
                <p key={'year-detail-'+i}>{detail}</p>
              )}
            </YearDetails>
          );
        })}
        <HorizontalLine  row={allYears?.length + 1} cols={maxCols}/>
        <FillerDiv row={allYears?.length+1}></FillerDiv>
        <HorizontalLine  row={allYears?.length + 2} cols={maxCols}/>
        <FillerDiv row={allYears?.length+2}></FillerDiv>
        <HorizontalLine  row={allYears?.length + 3} cols={maxCols}/>
        <FillerDiv row={allYears?.length+3}></FillerDiv>
        <HorizontalLine  row={allYears?.length + 4} cols={maxCols}/>
    </Container>
    </GridWrapper>

    <SectionTitle>FACULTY</SectionTitle>

    <BackButton color={GREEN} forceBlend={true}/>
    <FloatingMenu color={GREEN} forceBlend={true}/>
    <Footer/>
    </Wrapper>
    )}
    </Fragment>
  );
};
