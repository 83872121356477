import styled from "styled-components";
import { BLACK, GREEN } from "../../constants/colors";
import { SM, MD, LG } from "../../constants/mediaQueries";

const firstColWidth = 50;
const firstColFontSize = 24;
const firstRowHeight = 220;
const rowHeight = 80;
const selectedRowHeight = 250;

export const GridItem = styled.div`
  grid-row: ${props => props.row} / span 1;
  grid-column: ${props => props.col} / span 1;

  display: ${props => props.show ? 'block' : 'none'};
  position: relative;

  img {
    object-fit:cover;
    min-width: 100%;
    min-height: 100%;
    height: 20vh;
    max-height: 20vh;
    width: 100%;
  }
`;

export const TeacherTitle = styled.div`
  display: ${props => props.show ? 'flex' : 'none'};
  grid-row: ${props => props.row} / span 1;
  grid-column: ${props => props.col} / span 4;
  width: 100%;
  ${props => props.right ? 'text-align:right;' : ''}
  padding: 0.25rem 0.5rem;
  flex-direction: column;
  justify-content: space-between;

  h3, h4 {
    text-transform: uppercase;
    font-weight: bold;
    white-space: nowrap;
    margin-bottom: 0;
  }

  h3 {
    font-size: 3rem;
    line-height: 0.875;

  }
  h4 {
    font-size: 1.5rem;
  }

  @media (max-width: ${SM}) {
    display: none;
  }

`;

export const TeacherLink = styled.div`
  display: ${props => props.show ? 'block' : 'none'};
  grid-row: ${props => props.row} / span 1;
  grid-column: ${props => props.col} / span 4;
  width: 100%;
  ${props => props.right ? 'text-align:right;' : ''}
  padding: 0.25rem 0.5rem;

  a {
    white-space: nowrap;
    font-family: inherit;
    text-decoration: none;
    color: ${GREEN};
    &:hover {
      color: ${GREEN};
    }
    &:active {
      color: ${GREEN};
    }
  }

  @media (max-width: ${SM}) {
    display: none;
  }
`;

export const TeacherMobileDetails = styled.div`
  display: none;
  grid-row: ${props => props.row} / span 2;
  grid-column: ${props => props.col} / span 1;

  flex-direction: column;
  justify-content: space-between;

  width: 100%;
  padding: 0.5rem;
  background-color: ${GREEN};
  color: ${BLACK};
  font-weight: bold;
  z-index: 800;

  a, p, h5 {
    text-transform: uppercase;
    line-height: 1;
    margin: 0;
  }

  a {
    font-family: inherit;
    font-size: 1.5rem;
    text-decoration: none;
    color: ${BLACK};
    &:hover {
      color: ${BLACK};
    }
    &:active {
      color: ${BLACK};
    }
  }

  @media (max-width: ${SM}) {
    display: ${props => props.show ? 'flex' : 'none'};
  }
`;


export const TeacherImage = styled.div`
  display: ${props => props.show ? 'block' : 'none'};
  grid-row: ${props => props.row} / span ${props => props.rowSpan};
  grid-column: ${props => props.col} / span 2;
  height: ${props => props.row === 1 ? firstRowHeight + (props.rowSpan) * rowHeight : props.rowSpan * rowHeight };
  min-height: 100%;
  overflow: hidden;
  z-index: 800;

  img {
    object-fit: cover;
    width: 100%;
    min-height: 100%;
    margin: 1px;
  }

  @media (max-width: ${SM}) {
    display: none;
  }
`;

export const YearDetails = styled.div`
  grid-row: ${props => props.row} / span 1;
  grid-column: 2 / span 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0.75rem;

  p {
    margin-bottom: 0.25rem;
    font-wright: bold;
  }
  
  ${props => props.selected ? `
    height: 100%;
    background: ${GREEN};
    color: ${BLACK};
  ` : `
    p {
      display: none;
    }
  `}
`;

export const YearsContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background: ${BLACK};
  width: ${firstColWidth}px;
  object-fit: cover;
  display: flex;
  flex-direction: column;
  outline: 1px solid ${GREEN};
  z-index: 500;
`;

export const Year = styled.div`
  height: ${props => props.isSelected? `${selectedRowHeight}px ` : (props.isFirstRow ? `${firstRowHeight}px` : `${rowHeight}px`)};
  width: ${firstColWidth}px;

  font-weight: bold;
  font-size: ${firstColFontSize}px;

  cursor: pointer;

  div {
    height: 100%;
    display: flex;
    align-items: flex-end;
  }

  p {
    color: ${GREEN};
    width: 50px;
    height: 80px;
    writing-mode: tb;
    white-space: nowrap;
    transform: rotate(180deg);
    margin: 0;
    padding: ${(firstColWidth - firstColFontSize)/2}px;
    line-height: 0.875;
    display: block;
  }


  &:hover {
      background: ${GREEN};
      color: ${BLACK};
    p {
      color: ${BLACK};
    }
  }

  ${props => props.isSelected ? `
    background: ${GREEN};
    color: ${BLACK};
    p {
      color: ${BLACK};
    }
  }
  ` : ''}

`;

export const FillerDiv = styled.div`
  grid-row: ${props => props.row} / span 1;
  grid-column: 1 / span 1;
  background: ${BLACK};
  font-weight: bold;
  font-size: ${firstColFontSize}px;
  width: ${firstColWidth}px;
  height: ${rowHeight}px;
  position:relative;
  ${props => props.isFixed ? 'z-index: 500;' : ''}
`;

export const HorizontalLine = styled.div`
  grid-row: ${props => props.row};
  grid-column: 1 / ${props => props.cols + 3};
  height: 1px;
  background: ${GREEN};
  z-index: 700;
`;
export const VerticalLine = styled.div`
  grid-row: 1 / ${props => props.span};
  grid-column: ${props => props.col};
  scroll-snap-align: start;

  width: 1px;
  background: ${GREEN};
  z-index:200;
`;

export const MarginLines = styled.div`
  position: relative;
  display: grid;
  width: 50px;
  min-width: 50px;
  height: 100%;
  min-height: 100vh;
  ${props => {
    let rowTemplate = "";
    for (let i = 0; i < (props.rows + 1); i++) {
      rowTemplate += i === props.selectedRow? `${selectedRowHeight}px ` : (i === 0 ? `${firstRowHeight}px ` : `${rowHeight}px `);
    } 
    return `grid-template-rows: ${rowTemplate} ${rowHeight}px ${rowHeight}px;`;
  }}
`;

export const GridWrapper = styled.div`
  display: flex;
  min-height: 100vh;
  height: 100%;
  overflow: visible.
`;

export const Container = styled.div`
  position: relative;
  display: grid;
  scroll-snap-type: x mandatory;
  grid-template-columns: 1px 175px repeat(${props => props.cols}, 200px);
  ${props => {
    let rowTemplate = "";
    for (let i = 0; i < (props.rows + 1); i++) {
      rowTemplate += i === props.selectedRow? `${selectedRowHeight}px ` : (i === 0 ? `${firstRowHeight}px ` : `${rowHeight}px `);
    } 
    return `grid-template-rows: ${rowTemplate} ${rowHeight}px ${rowHeight}px;`;
  }}
  min-height: 100vh;
  overflow: scroll;
  height: 100%;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Wrapper = styled.div`
  background-color: ${BLACK};
  color: ${GREEN};
  padding: 0;
  position: relative;
`;

export const SectionTitle = styled.h1`
  font-size: 7rem;
  position: fixed;
  text-align: right;
  right: 0;
  bottom: 0;
  padding-bottom: 0.25rem;
  color: ${GREEN};
  font-weight: bold;
  z-index: 1000;

  mix-blend-mode: difference;

  @media (max-width: ${LG}) {
    margin-top: 16px;
  }
  @media (max-width: ${MD}) {
    font-size: 5rem;
  }
  @media (max-width: ${SM}) {
    font-size: 4rem;
    width: 100%;
    bottom: unset;
    top: 0;
  }

  @media (max-width: 350px) {
    font-size: 3rem;
  }
`;

